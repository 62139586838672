import "./App.css";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter, useNavigate } from "react-router-dom";
import React, { Suspense, useEffect } from "react";
// import { Spinner } from "react-bootstrap";
import AuthProvider from "./Context/AuthProvider";
import { Box } from "@mui/material";
import loading from "./Components/Vendors/Images/loading.gif";
import { useState } from "react";
import { createContext } from "react";
import StatusProvider from "./Context/StatusProvider";
const Routing = React.lazy(() => import("./Components/Routing/Routing.js"));
export const RenderContext = createContext();
export const UserContext = createContext();
export const userTypeContext = createContext();
function App() {
  const [render, setRender] = useState(false);
  const [userData, setUserData] = useState({});
  const [userTypeInfo, setUserTypeInfo] = useState({});
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  // const navigate = useNavigate();

  // if (!isOnline) {
  //   navigate("/offlinePage/");
  // } else {
  //   navigate("/forum/");
  // }
  const decodeLocalData = (name) => {
    let storeData = window.localStorage.getItem(name);
    let result;
    if (storeData) {
      result = atob(storeData);
    } else {
      result = null;
    }
    return result ? JSON.parse(result) : null;
  };

  // useEffect(() => {
  let localData = decodeLocalData("ft_sl@g");
  // }, []);
  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);
  return (
    <div>
      <UserContext.Provider value={[userData, setUserData]}>
        <userTypeContext.Provider value={[userTypeInfo, setUserTypeInfo]}>
          <RenderContext.Provider value={[render, setRender]}>
            <StatusProvider>
              <AuthProvider>
                <Suspense
                  fallback={
                    <Box
                      sx={{
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={loading}
                        alt="loading"
                        style={{
                          width: "200px",
                          height: "200px",
                        }}
                      />
                    </Box>
                  }
                >
                  <BrowserRouter>
                    <Routing />
                  </BrowserRouter>
                </Suspense>
              </AuthProvider>
            </StatusProvider>
          </RenderContext.Provider>
        </userTypeContext.Provider>
      </UserContext.Provider>
    </div>
  );
}

export default App;
