import React, { useState } from "react";
import { createContext } from "react";

export const StatusContext = createContext();

const StatusProvider = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  setInterval(() => {
    setIsOnline(navigator.onLine);
  }, 4000);

  return (
    <StatusContext.Provider value={{ isOnline, setIsOnline }}>
      {children}
    </StatusContext.Provider>
  );
};

export default StatusProvider;
